<script lang="ts" setup>
import { computed, ref, onMounted, onUnmounted, watch } from 'vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import IconChevronRight from '@lahaus-roomie/roomie/src/assets/icons/chevron-right.svg'
import type { Props } from './types'
import type { LocaleCode } from '@/config/locale-domains'
import type { InvestmentProjectsSummaryResponse } from '@/server/api/buyer-front/types'

import InvestmentProjectCard from '@/components/InvestmentProjectCard/index.vue'
import { formatStringToUrlParam } from '@/utils/filters'

withDefaults(defineProps<Props>(), {
  screen: 'home'
})
const { t } = useI18n()

const { locale } = useI18n()
const { $sobreplanosUrls } = useNuxtApp()
const urlBase = $sobreplanosUrls[locale.value as LocaleCode]

const locationsResonse = await useFetch<string []>(`${urlBase}/api/v1/real_estate_search`, {
  method: 'POST',
  body: {
    type: 'listing-investors-business-hubs',
    filters: {}
  }
})

const allProjectsLabel = 'Todas'
const availableLocations = locationsResonse.data?.value ?? []

const locations = computed(() => [allProjectsLabel, ...availableLocations])

const selectedLocation = ref<string | undefined>(undefined)

const paginationLimit = 20

const { data, pending } = await useAsyncData<InvestmentProjectsSummaryResponse>(
  () => $fetch(`${urlBase}/api/v1/real_estate_search`, {
    method: 'POST',
    body: {
      type: 'listing-investors-paginated',
      filters: {
        business_hubs: selectedLocation.value
      },
      pagination: {
        limit: paginationLimit
      }
    }
  }),
  {
    watch: [selectedLocation]
  }
)

const filteredProjects = computed(() => {
  if (!data?.value) return []

  const limit = sliderScrollLeft.value > 0 ? paginationLimit : 4

  return data?.value.results.slice(0, limit)
})

const handleLocationSelectedClick = (location: string) => {
  if (location === allProjectsLabel) selectedLocation.value = undefined
  else selectedLocation.value = location
}

const isLocationSelected = (location: string) => {
  if (location === allProjectsLabel) return !selectedLocation.value
  return location === selectedLocation.value
}

const intervalId = ref<number | null>(null)

watch(selectedLocation, () => {
  clearInterval(intervalId.value!)
})

const slider = ref<HTMLElement | null>()
const sliders = ref()
const sliderScrollLeft = ref(0)
const scrollLeftLimit = ref(0)

const startAutoSlide = () => {
  intervalId.value = window.setInterval(() => {
    moveRight()
  }, 5000)
}
const setAutoSlideObserver = () => {
  const container = document.querySelector('.projects-by-city')

  if (container) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.disconnect()
          startAutoSlide()
        }
      })
    }, {
      threshold: 0
    })

    observer.observe(container)
  }
}

onMounted(() => {
  if (slider.value) scrollLeftLimit.value = slider.value.scrollWidth - slider.value!.clientWidth
  setAutoSlideObserver()
  setIsShowSeeMoreAction()
})

watch(sliderScrollLeft, () => {
  scrollLeftLimit.value = slider.value!.scrollWidth - slider.value!.clientWidth
})

onUnmounted(() => {
  clearInterval(intervalId.value!)
})

function getCardWidth ():number {
  return sliders.value.children[0].offsetWidth + 40
}

function moveLeft (manual = false) {
  if (manual) clearInterval(intervalId.value!)

  const newScrollLeft = sliderScrollLeft.value - getCardWidth() - 40

  sliderScrollLeft.value = newScrollLeft < 0 ? 0 : newScrollLeft
  scrollSlider()
}

function moveRight (manual = false) {
  if (manual) clearInterval(intervalId.value!)

  const cardWidth = getCardWidth()
  const newScrollLeft = sliderScrollLeft.value + cardWidth

  sliderScrollLeft.value = newScrollLeft
  scrollSlider()
}

function scrollSlider () {
  slider.value!.scroll({
    behavior: 'smooth',
    left: sliderScrollLeft.value
  })
}

const handleScroll = (event: Event) => {
  const target = event.target as HTMLElement
  const scrollAmount = target.scrollLeft

  if (scrollAmount !== sliderScrollLeft.value) {
    clearInterval(intervalId.value!)
  }
  sliderScrollLeft.value = scrollAmount
}

const showSeeMore = ref<boolean>(true)

const setIsShowSeeMoreAction = () => {
  showSeeMore.value = document.getElementsByClassName('home-faqs').length > 0
}

const scrollToFaqs = () => {
  const faqs = document.getElementsByClassName('home-faqs')[0]
  if (faqs) {
    faqs.scrollIntoView({ behavior: 'smooth' })
  }
}
const remainingProperties = computed(() => {
  const totalProperties = data.value?.pagination.total
  return totalProperties! - paginationLimit
})

const goToSimulator = () => {
  return navigateTo({
    path: '/venta/propiedades',
    query: {
      locations: formatStringToUrlParam(selectedLocation.value ?? '')
    }
  })
}

</script>

<script lang="ts">
export default {
  name: 'ProjectsByLocation'
}
</script>

<template>
  <section
    v-if="availableLocations.length && filteredProjects.length"
    id="Properties"
    class="projects-by-city mx-auto px-24 py-32 md:py-56 xl:px-0">
    <div class="flex flex-col md:flex-row max-w-[97rem] mx-auto">
      <img
        class="mb-16 mr-16"
        src="https://lahaus.imgix.net/static/home/verified-icon.svg"
        alt="verified tag"
        width="60"
        height="60">

      <div>
        <h3 class="projects-by-city__title text-24 lg:text-32 leading-24 lg:leading-[3.4rem]">
          <span>
            {{ t('title.part1') }}
          </span>

          <span class="font-semibold">
            {{ t('title.part2') }}
          </span>

          <span class="font-semibold text-accent-700 block md:inline md:pr-10">
            {{ t('title.part3') }}
          </span>

          <button
            v-if="showSeeMore"
            id="properties-view-more"
            class="mt-16 md:mt-0 text-carbon-600 text-18 underline flex md:inline-flex items-center gap-4"
            :data-lh-id="`${screen}-button-properties-view-more`"
            @click="scrollToFaqs">
            {{ t('viewMore') }}
            <IconChevronRight class="w-20 h-20 pointer-events-none fill-carbon-600" />
          </button>
        </h3>
      </div>
    </div>

    <div class="roomie-tabs mt-32">
      <div class="roomie-tabs__container">
        <ul class="roomie-tabs__header">
          <li
            v-for="(location) in locations"
            :key="location"
            class="roomie-tabs__item"
            :data-lh-id="`${screen}-location_tab-selected`"
            :class="{ 'is-selected': (isLocationSelected(location)) }"
            @click="handleLocationSelectedClick(location)">
            {{ location }}
          </li>
        </ul>
      </div>

      <div
        class="roomie-tabs__content flex items-center justify-center transition-all duration-500"
        :class="`${pending ? 'blur-sm' : 'blur-none'}`">
        <div
          v-if="filteredProjects.length >= 3"
          class="mr-8 hidden lg:inline z-10">
          <ClientOnly>
            <button
              id="projects-by-city__slider-left"
              class="projects-by-city__slider-button"
              :data-lh-id="`${screen}-button-carousel-left`"
              :disabled="sliderScrollLeft === 0"
              aria-label="Atrás"
              @click="moveLeft(true)">
              <span class="w-32 h-32">
                <NuxtIsland name="IconChevronLeft" />
              </span>
            </button>
          </ClientOnly>
        </div>

        <div
          id="projects-by-city__slider"
          ref="slider"
          :data-lh-id="`${screen}-slider-projects-by-location`"
          class="projects-by-city__slider -mr-24 overflow-x-auto overflow-y-hidden mt-22 mb-16 scrollbar-hidden lg:mr-0"
          @scroll="(event) => handleScroll(event)">
          <div
            ref="sliders"
            class="max-w-[97rem] flex gap-16">
            <InvestmentProjectCard
              v-for="(project) in filteredProjects"
              :key="project.code"
              class="relative"
              :project="project"
              :blank="false"
              :site="screen"
              clickable />

            <div
              v-if="remainingProperties > 0"
              class="more-projects-card investment-project-card relative">
              <img
                src="https://lahaus.imgix.net/static/home/card-show-more-bento.png?auto=compress,enhance,format&h=400&w=312"
                class="object-cover h-full w-full"
                alt="Más Proyectos"
                loading="lazy"
                height="400"
                width="312">

              <div class="more-projects-card__content">
                <div class="flex flex-col items-center">
                  <p class="text-70 h-72 tracking-6">
                    +{{ remainingProperties }}
                  </p>

                  <p class="text-20 mt-8">
                    {{ t('properties') }}
                  </p>

                  <RoomieButton
                    id="projects-by-location-see-all-button"
                    :data-lh-id="`${screen}-button-projects-by-location-see-all`"
                    class="mt-16 w-full !bg-white"
                    type="button"
                    variant="outlined"
                    :aria-label="t('seeAll')"
                    @click="goToSimulator">
                    {{ t('seeAll') }}
                  </RoomieButton>
                </div>
              </div>
            </div>

            <span class="block md:hidden w-1 flex-none" />
          </div>
        </div>

        <div class="projects-by-city__shadow h-400 shadow-[0_0_30px_30px_#fff] hidden lg:block z-[5]" />

        <div
          v-if="filteredProjects.length >= 3"
          class="ml-8 hidden lg:inline z-10">
          <ClientOnly>
            <button
              id="projects-by-city__slider-right"
              class="projects-by-city__slider-button"
              :data-lh-id="`${screen}-button-carousel-right`"
              :disabled="sliderScrollLeft === scrollLeftLimit"
              aria-label="Siguiente"
              @click="moveRight(true)">
              <span class="w-32 h-32">
                <NuxtIsland name="IconChevronRight" />
              </span>
            </button>
          </ClientOnly>
        </div>
      </div>

      <p class="text-12 text-carbon-600 text-center">
        {{ t('disclaimer') }}
      </p>
    </div>
  </section>
</template>

<style lang="scss" src="./_index.scss"></style>

<i18n src="./i18n.json" lang="json"></i18n>
